<template>
    <slot></slot>
    <IdentityVerification v-on:verification_identity="changeIdentityVerification($event)" :view_class_view="viewClassView" :matiClientId="matiClientId" :matiClientSecretId="matiClientSecretId" :matiuserid="matiClientUserId" :admissionId="admissionId" :business="business" :tableType="tableType" />
</template>

<script>

  export default {
    name: "IdentityVerficationComponent",
    setup(props, {emit}){
      let viewClassView = {
          div_content_button: "w-full text-center",
      };

      let matiClientId = process.env.VUE_APP_MATI_CLIENTID;
      let matiClientSecretId = process.env.VUE_APP_MATI_CLIENT_SECRETID;
      let matiClientUserId = process.env.VUE_APP_MATI_ID_AND_BIOMETRICS;
      let business = "foncabsa";
      let tableType = "admission_requests";
      const admissionId = sessionStorage.getItem("admissionRequestId");

      function changeIdentityVerification(value){
        console.log("🚀 ~ file: IdentityVerificationComponent.vue:22 ~ changeIdentityVerification ~ value", value)
        emit('verification-identity', value);
      }
      
      return { 
        viewClassView,
        matiClientId,
        matiClientSecretId,
        matiClientUserId,
        admissionId,
        changeIdentityVerification,
        business,
        tableType
      }
    }
  }
</script>
  