<template>
    <slot></slot>
    <Beneficiaries
        ref="beneficiariesRef"
        :view_class_view="viewClassView"
        :validations="rulesValidations"
    />
</template>
  
<script>
import { reactive, ref, onBeforeMount } from "vue";
import {
  helpers,
  requiredIf,
  minLength,
  maxLength,
} from "@vuelidate/validators";
import Swal from "sweetalert2";
import { getTokenDecoden } from "@/helpers/tokenauth";

export default {
  name: "BeneficiariesComponent",
  setup() {
    var listMonths = ref([
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ]);

    const birthdayApplicant = ref('');
    const beneficiariesRef = ref(null);
    let rulesValidations = reactive({
      beneficiaries: {
        $each: helpers.forEach({
          name: {
            required: helpers.withMessage(
              "El campo nombre es requerido.",
              requiredIf(function () {
                return true;
              })
            ),
            minLength: helpers.withMessage(
              "El campo nombre debe contener mínimo 2 caracteres.",
              minLength(2)
            ),
            maxLength: helpers.withMessage(
              "El campo nombre debe contener máximo 30 caracteres.",
              maxLength(30)
            ),
            pattern: helpers.withMessage(
              "El campo nombre no acepta caracteres especiales.",
              function (value, index) {
                if (index.name != "") {
                  return new RegExp(/^[a-zA-ZÀ-ÿ\-\s]+$/).test(value);
                } else {
                  return true;
                }
              }
            ),
          },
          paternal_name: {
            required: helpers.withMessage(
              "El apellido paterno es requerido.",
              function (data, index) {
                if (index.maternal_name == "") {
                  return index.paternal_name != "" ? true : false;
                } else {
                  return true;
                }
              }
            ),
            minLength: helpers.withMessage(
              "El campo apellido paterno debe contener mínimo 2 caracteres.",
              minLength(2)
            ),
            maxLength: helpers.withMessage(
              "El campo apellido paterno debe contener máximo 30 caracteres.",
              maxLength(30)
            ),
            pattern: helpers.withMessage(
              "El campo apellido paterno no acepta caracteres especiales.",
              function (value, index) {
                if (index.paternal_name != "") {
                  return new RegExp(/^[a-zA-ZÀ-ÿ\-\s]+$/).test(value);
                } else {
                  return true;
                }
              }
            ),
          },
          maternal_name: {
            required: helpers.withMessage(
              "El apellido materno es requerido.",
              function (data, index) {
                if (index.paternal_name == "") {
                  return index.maternal_name != "" ? true : false;
                } else {
                  return true;
                }
              }
            ),
            minLength: helpers.withMessage(
              "El campo apellido materno debe contener mínimo 2 caracteres.",
              minLength(2)
            ),
            maxLength: helpers.withMessage(
              "El campo apellido materno debe contener máximo 30 caracteres.",
              maxLength(30)
            ),
            pattern: helpers.withMessage(
              "El campo apellino materno no acepta caracteres especiales.",
              function (value, index) {
                if (index.maternal_name != "") {
                  return new RegExp(/^[a-zA-ZÀ-ÿ\-\s]+$/).test(value);
                } else {
                  return true;
                }
              }
            ),
          },
          middle_name: {
            minLength: helpers.withMessage(
              "El campo segundo nombre tiene que tener mínimo 2 caracteres.",
              minLength(2)
            ),
            maxLength: helpers.withMessage(
              "El campo segundo nombre tiene que tener máximo 30 caracteres.",
              maxLength(30)
            ),
            pattern: helpers.withMessage(
              "El campo segundo nombre no acepta caracteres especiales.",
              function (value, index) {
                if (index.middle_name != "") {
                  return new RegExp(/^[a-zA-ZÀ-ÿ\-\s]+$/).test(value);
                } else {
                  return true;
                }
              }
            ),
          },
          relationship: {
            required: helpers.withMessage(
              "El campo parentesto es requerido.",
              requiredIf(function () {
                return true;
              })
            ),
          },
          birthday: {
            minValue: helpers.withMessage(
              "El campo fecha de nacimiento es invalido.",
              function (data, index) {
                console.log("=>", beneficiariesRef.value);
                if (
                  new Date(data) <= new Date(birthdayApplicant.value) &&
                  index.relationship == "HIJO"
                ) {
                  Swal.fire({
                        title: "Aviso",
                        text: "La fecha de nacimiento del beneficiario hijo no puede ser mayor a la del titular/socio.",
                        icon: "warning",
                        confirmButtonColor: '#FEB72B',
                      });     
                  return false;
                } else {
                  if (
                    new Date(data) >= new Date("1995-10-24") &&
                    (index.relationship == "PADRE" ||
                      index.relationship == "MADRE")
                  ) {
                    Swal.fire({
                          title: "Aviso",
                          text: "La fecha de nacimiento del padre/madre no debe ser menor a la del titular/socio",
                          icon: "warning",
                          confirmButtonColor: '#FEB72B',
                          });    
                    return false;
                  } else {
                    if (
                      calculateAge(index.birthday) != true &&
                      index.relationship == "CONYUGE" &&
                      index.birthday != ""
                    ) {
                       Swal.fire({
                              title: "Aviso",
                              text: "El Cónyuge debe ser mayor de edad",
                              icon: "warning",
                              confirmButtonColor: '#FEB72B',
                            });    
                      return false;
                    } else {
                      if (
                        new Date(data) >= new Date("1995-10-24") &&
                        index.relationship == "ABUELO"
                      ) {
                        Swal.fire({
                              title: "Aviso",
                              text: "La fecha de nacimiento del Abuelo no debe ser menor a la del titular/socio",
                              icon: "warning",
                              confirmButtonColor: '#FEB72B',
                            });  
                        return false;
                      } else {
                        return true;
                      }
                    }
                  }
                }
              }
            ),
            required: helpers.withMessage(
              "El campo fecha de nacimiento es requerido.",
              function (data, index) {
                if (index.birthday == "") {
                  return false;
                } else {
                  return true;
                }
              }
            ),
          },
          percentage: {
            required: helpers.withMessage(
              "El campo porcentaje es requerido.",
              requiredIf(function () {
                return true;
              })
            ),
          },
        }),
      },
    });
    
    onBeforeMount(()=>{
      getApplicantInfo();
    });

    function getApplicantInfo(){
      const auth = getTokenDecoden();
      birthdayApplicant.value = auth.obj.person.birthday;
    }
    
    function calculateAge(birthday) {
      if (birthday != "") {
        let date = birthday.split("-");
        if (isNaN(date[1])) {
          let monthNumber = listMonths.value.findIndex(
            (month) => month === date[1]
          );
          monthNumber =
            monthNumber < 10 ? `0${monthNumber + 1}` : monthNumber + 1;
          birthday = `${date[0]}-${monthNumber}-${date[2]}`;
        }
      }

      var today = new Date();
      var birthDate = new Date(birthday);
      var age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() <= birthDate.getDate())) {
        age--;
      }
      return age >= 18;
    }

    let viewClassView = {
      classInputs: {
        valid: "border-blue-600",
        no_valid: "border-red-600",
        default:
          "py-2 px-2 rounded-md border-2 border-sm focus:border-blue-600 mb-2 mt-2 shadow-md focus:outline-none ",
      },
      div_container_error: {
        container: "my-2",
        text_error: "text-red-600",
      },
      div_container_section: {
        container: "w-full",
        label: "hidden",
        form: "container w-full mt-5  grid grid-cols-1 md:grid-cols-3 gap-4",
      },
    };

    return {
      viewClassView,
      rulesValidations,
      beneficiariesRef,
    };
  },
};
</script>