<template>
    <div>
        <slot></slot>
        <EconomicInformation ref="economicInformationRef" :view_class_view="viewClassView" :validations="ruleValidations"/>
    </div>
</template>

<script>
import { ref } from 'vue';
import {
  helpers,
  requiredIf,
} from "@vuelidate/validators";
export default {
    name: "EconomicInformationComponent",
    setup() {
        let economicInformationRef = ref(null);
        let viewClassView={
            classInputs:{
                valid:"border-blue-600",
                no_valid:"border-red-600",
                default:"w-full py-2 px-2 rounded-md border-2 border-sm focus:border-blue-600 mb-2 mt-2 shadow-md focus:outline-none "
            },
            div_container_error:{
                container:"my-2",
                text_error:"text-red-600"
            },
            div_container:"grid grid-cols-1 sm:gap-5 my-8 ml-8"
        };

        let ruleValidations = ref({
            income_frecuency: {
                required: helpers.withMessage("El campo periodo de ingreso es requerido.", requiredIf(function() {
                    return true;
                }))
            }
        });

        return { 
            viewClassView,
            ruleValidations,
            economicInformationRef
        }
    }
}

</script>