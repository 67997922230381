<template>
    <div class="mx-auto py-10">
        <Loading :isLoading="isLoading" :key="isLoading"/>
        
        <component ref="moduleRef" v-if="moduleKey!=''" v-bind:is="moduleKey" v-on:verification-identity="changeVerificationIdentity($event)" v-on:video-contract="saveDataByCategory($event)" :data="componentData">
            <p class="normal-case text-xl md:text-2xl text-thin mt-2 mb-5 md:mb-7 text-center">{{ title }}</p>
            <div v-html="content">
            </div>
        </component>

        <input v-show="requiresActionBySending" type="button" value="Guardar" class="w-56 px-2 py-3 bg-mustard text-white font-bold rounded mx-auto mt-10 cursor-pointer block " @click="saveDataByCategory()">
    </div>
</template>

<script>

import { ref, onBeforeMount, onErrorCaptured, getCurrentInstance, computed, watch} from 'vue';
import { useRoute, useRouter } from "vue-router";
import { getTokenDecoden } from "@/helpers/tokenauth.js";

import Video from '@/classes/VideoRecorder';
import { getAdmissionRequest, getMissingData, getMissingDataObject, updateHousingData, updateSchoolData, updateEconomicInformation, updateBeneficiaries } from "@/api/user";

import Swal from 'sweetalert2';
import Loading from "@/components/Loading/VueLoading.vue";

import IdentityVerificationComponent from "@/components/Cuenta/UpdateData/IdentityVerificationComponent.vue";
import HousingInfoComponent from "@/components/Cuenta/UpdateData/HousingInfoComponent.vue";
import SchoolingComponent from "@/components/Cuenta/UpdateData/SchoolingComponent.vue";
import BeneficiariesComponent from '@/components/Cuenta/UpdateData/BeneficiariesComponent.vue';
import VideoContractComponent from '@/components/Cuenta/UpdateData/VideoContractComponent.vue';
import EconomicInformationComponent from '@/components/Cuenta/UpdateData/EconomicInformationComponent.vue';

export default {
    name: 'UpdatePersonalData',
    setup(){

        const route = useRoute();
        const router = useRouter();
        const path = ref("");
        const videoClass = ref(null);
        const nameVideo = "video";
        const modulesObjectRequiringInformation = {
            "VideoContractComponent": getAdmissionRequestData
        };

        let moduleRef = ref(null);
        let isLoading = ref(true);
        let personId = ref("");
        let clientId = ref("");
        let title = ref("");
        let content = ref("");
        let moduleKey = ref("");
        let category = computed(()=> {
            return route.params.category;
        });
        let requiresActionBySending = ref(false);
        let app = getCurrentInstance();
        let componentData = ref(null);

        watch(category, async (value)=> {
            if(value == undefined || value == ""){
                isLoading.value = false;
                return;
            }
            
            await loadModule();
        });

        onBeforeMount(async()=>{
            app = app.appContext.config.globalProperties;
            await loadModule();
        });

        onErrorCaptured((err)=> {
           console.log("onErrorCaptured", err); 
        });

        async function loadModule(){
            isLoading.value = true;
            path.value = getFullPath();
            
            loadAccountInformation();

            let isValid = await validateCategory();
            if(isValid.status){

                moduleKey.value = isValid.data.module_key;
                if(moduleKey.value == ""){
                    showMessage('info', 'Aviso', `Módulo no disponible por el momento`);
                    router.push({path: '/cuenta/principal'});
                    return;
                }

                title.value = isValid.data.module_name;
                content.value = isValid.data.module_content;
                requiresActionBySending.value = validateModuleSendingAction(category.value);

                await loadModuleInformation();
                videoClass.value = new Video();
                isLoading.value = false;
                return;
            }
            
            isLoading.value = false;
            router.push({path: '/cuenta/principal'});
        }

        function loadAccountInformation() {
            var auth = getTokenDecoden();

            if (auth.obj.role == "member" || auth.obj.role == "cabsa_member") {
                personId.value = auth.obj.person["id"];
                clientId.value = auth.obj.person.client_id;
            } else {
                personId.value = auth.obj.personnel_info["id"];
                clientName.value = "";
            }
        }

        async function loadModuleInformation(){
            try {
                if(typeof modulesObjectRequiringInformation[moduleKey.value] == 'function'){
                    modulesObjectRequiringInformation[moduleKey.value]();
                    return;
                }

                throw "Modulo no necesita carga de información";
            }catch(err){
                console.log(err);
            }
        }

        function validateModuleSendingAction(category){
            let moduleSendingAction = false;
            switch (category) {
                case "video-contrato":
                    moduleSendingAction = false;
                    break;
                case "validacion-identidad":
                    moduleSendingAction = false;
                    break;
                default:
                    moduleSendingAction = true;
                    break;
            }

            return moduleSendingAction;
        }

        function getFullPath(){
            return route.fullPath;
        }

        async function getAdmissionRequestData (){
            let token = sessionStorage.getItem("login");
            let admissionRequestData = await getAdmissionRequest(token, personId.value).then(resp => { return {status: true, message: resp.data.response.message, data: resp.data.response.admission_request}}).catch(err => { return { status: false, message: err.response.data.response.message, data: null } });

            if(!admissionRequestData.status){
                showMessage((response.status)? 'success': 'error', (response.status)? 'Aviso': 'Error', app.$filters.formatCapitalize(response.message));
                return;
            }
            
            let admissionInformation = admissionRequestData.data.admission_information;
            let paymentMethod = admissionInformation.saving_registration_attributes.payment_method.toString().toUpperCase();
            let paymentFrecuency = admissionInformation.saving_registration_attributes.payment_frecuency.toString().toUpperCase();
            let fullName = `${admissionInformation.name} ${admissionInformation.paternal_name} ${admissionInformation.maternal_name}`;

            componentData.value = {
                payment_frecuency: admissionInformation.saving_registration_attributes.payment_frecuency,
                payment_method: admissionInformation.saving_registration_attributes.payment_method,
                bussines_name: "FONCABSA",
                text_description: `¡Felicidades! está a punto de concluir el proceso, agradecemos su tiempo, como paso final de la captura de datos, es necesario que inicie la grabación donde manifiesta su deseo por voluntad propia para formar parte de esta cooperativa, así mismo nos indique que autoriza un descuento vía ${paymentMethod} con una frecuencia de pago ${paymentFrecuency}. Favor de iniciar la grabación para dar por terminado el proceso de captura de sus datos.`,
                text_video: `Yo "${fullName}" deseo por voluntad propia formar parte de la cooperativa, por lo que  manifiesto que la información y documentación proporcionada es verídica, en caso de existir falsedad en ella, tengo pleno conocimiento de que mi solicitud será rechazada. De ser aceptada mi solicitud, autorizo para que realice un descuento vía “${paymentMethod}” con una frecuencia de pago “${paymentFrecuency}”`
            };
        }

        async function validateCategory(){
            let missinDataJSON = await getStructureMissingData();

            let missingDataKeyList = Object.keys(missinDataJSON).filter(elemet => missinDataJSON[elemet].route == path.value);
            if(missingDataKeyList.length == 0){
                return { status: false, data: null};
            }

            let missingDataKey = missingDataKeyList[0];
            let missingData = await getMissingClientData();
            if(missingData[missingDataKey] == undefined){
                return { status: false, data: null};
            }

            return { status: !missingData[missingDataKey], data: missinDataJSON[missingDataKey]};
        }

        async function getStructureMissingData(){
            return await getMissingDataObject().then(resp =>  resp.data ).catch(error => null); 
        }
        
        async function getMissingClientData(){
            let token = sessionStorage.getItem("login");
            return await getMissingData(token, clientId.value, "new_client").then(resp => (!resp.data.response.has_error)? resp.data.response.result: null).catch(err => null);
        }

        function saveDataByCategory(value = null){
            switch (category.value) {
                case "residencia":
                    saveHousingData();
                    break;
                
                case "nivel-de-educacion":
                    saveSchoolingData();
                    break;
                
                case "salario":
                    saveEconomicInformationData();
                    break;

                case "beneficiarios":
                    saveBeneficiariesData();
                    break;

                case "video-contrato":
                    saveVideoContractData(value);
                    break;

                default:
                    break;
            }
        }

        function changeVerificationIdentity(value){
            isLoading.value = true;
            
            createStorage('verfication-identity', value)
            showMessage('success', 'Aviso', 'Verificación completada');
            isLoading.value = false;

            router.push({path: '/cuenta/principal'});
        }

        function createStorage(name, value){
            sessionStorage.setItem(name, JSON.stringify(value))
        }

        async function saveVideoContractData(value){
            
            let token = sessionStorage.getItem("login");
            videoClass.value.extension = value.extension;
            videoClass.value.recordingBase64 = `${ value.recordingBase64 }`;
            let response = await videoClass.value.uploadVideo(token, nameVideo, 'admission_request', clientId.value).then(() =>  { return {status: true, message: "Video contrato completado exitosamente"}} ).catch(() => { return {status: false, message: "Ha ocurrido un error al guardar video contrato"}});
            showMessage((response.status)? 'success': 'error', (response.status)? 'Aviso': 'Error', app.$filters.formatCapitalize(response.message));

            if(response.status){
                router.push({path: '/cuenta/principal'});
            }
        }
        
        async function saveBeneficiariesData(){
            isLoading.value = true;
            let beneficiariesInfo = !moduleRef.value.beneficiariesRef.touch();
            if(beneficiariesInfo){
                let token = sessionStorage.getItem("login");
                let beneficiariesList = moduleRef.value.beneficiariesRef.listBeneficiaries.beneficiaries.map(beneficiary => {
                    return {
                        "name": beneficiary.name.trim().toUpperCase(),
                        "middle_name": beneficiary.middle_name.trim().toUpperCase(),
                        "paternal_name": beneficiary.paternal_name.trim().toUpperCase(),
                        "maternal_name": beneficiary.maternal_name.trim().toUpperCase(),
                        "gender": "",
                        "percentage": beneficiary.percentage,
                        "birthday": beneficiary.birthday,
                        "relationship": beneficiary.relationship.toString().toLowerCase()
                    };
                });
                
                let response = await updateBeneficiaries(token, clientId.value, beneficiariesList).then(resp => { return { status: true, message: resp.data.response.message }}).catch((err) => { return { status: false, message: err.response.data.response.message } })
                showMessage((response.status)? 'success': 'error', (response.status)? 'Aviso': 'Error', app.$filters.formatCapitalize(response.message));
                
                if(response.status){
                    router.push({path: '/cuenta/principal'});
                }
            }
            
            isLoading.value = false;
        }

        async function saveEconomicInformationData(){
            isLoading.value = true;

            let economicInformationInfo = moduleRef.value.economicInformationRef;
            let validate = economicInformationInfo.execute_validation();
            if(validate){
                let token = sessionStorage.getItem("login");
                let response = await updateEconomicInformation(token, clientId.value, validate.monthly_income.toString(), validate.liquidity.toString(), validate.income_frecuency.toString().toLowerCase()).then(resp => { return { status: true, message: resp.data.response.message }}).catch((err) => { return { status: false, message: err.response.data.response.message } })
                showMessage((response.status)? 'success': 'error', (response.status)? 'Aviso': 'Error', app.$filters.formatCapitalize(response.message));
                router.push({path: '/cuenta/principal'});
            }
            
            isLoading.value = false;
        }

        async function saveSchoolingData(){
            isLoading.value = true;

            let schoolingInfo = moduleRef.value.schoolingRef;
            let validate = schoolingInfo.execute_validation();
            if(validate){
                let educationLevel = validate.educationLevel;
                let token = sessionStorage.getItem("login");
                let response = await updateSchoolData(token, clientId.value, educationLevel).then(resp => { return { status: true, message: resp.data.response.message }}).catch((err) => { return { status: false, message: err.response.data.response.message }});
                showMessage((response.status)? 'success': 'error', (response.status)? 'Aviso': 'Error', app.$filters.formatCapitalize(response.message));
                
                if(response.status){
                    router.push({path: '/cuenta/principal'});
                }
            }
            isLoading.value = false;
        }

        async function saveHousingData(){
            isLoading.value = true;

            let housingInfo = moduleRef.value.housingInfoRef;
            let validate = housingInfo.execute_validation();
            if(validate){
                let housingModel = housingInfo.housingInfoModel;
                let token = sessionStorage.getItem("login");
                let response = await updateHousingData(token, clientId.value, housingModel.type.toUpperCase(), housingModel.startOfResidency).then(resp => { return { status: true, message: resp.data.response.message }}).catch((err) => { return { status: false, message: err.response.data.response.message }});
                showMessage((response.status)? 'success': 'error', (response.status)? 'Aviso': 'Error', app.$filters.formatCapitalize(response.message));
                
                if(response.status){
                    router.push({path: '/cuenta/principal'});
                }
            }

            isLoading.value = false;
        }

        function showMessage(type, title, message, confirmButtonText = "OK", confirmButtonColor= "#FEB72B"){
            Swal.fire({
                title: title,
                html: message,
                icon: type,
                confirmButtonText: confirmButtonText,
                confirmButtonColor: confirmButtonColor
            });
        }

        return {
            isLoading,
            category,
            title,
            content,
            moduleKey,
            moduleRef,
            saveDataByCategory,
            requiresActionBySending,
            changeVerificationIdentity,
            componentData
        }
    },
    components: {
        Loading,
        IdentityVerificationComponent,
        HousingInfoComponent,
        SchoolingComponent,
        BeneficiariesComponent,
        VideoContractComponent,
        EconomicInformationComponent
    }
}
</script>