<template>
    <slot></slot>
    <Schooling ref="schoolingRef" :view_class_view="viewClassView" :validations="rulevalidations" />
</template>
  
<script>
import { ref } from 'vue';
import {
  helpers,
  requiredIf,
} from "@vuelidate/validators";

export default {
  name: "SchoolingComponent",
  setup() {

    let schoolingRef = ref("");

    let viewClassView = {
      title: "font-normal text-4xl pt-20",
      div_content_sub_text: "pb-5 mt-5",
      div_container_section: {
        container: "w-96 mx-auto"
      },
      classInputs: {
        valid: "border-blue-600",
        no_valid: "border-red-600",
        default:
          "w-96 mx-auto py-2 px-2 rounded-md border-2 border-sm focus:border-blue-600 mb-2 mt-2 shadow-md focus:outline-none ",
      },
      intput_email_client: {
        valid: "border-blue-600",
        no_valid: "border-red-600",
        default:
          "w-96 mx-auto py-2 px-2 rounded-md border-2 border-sm focus:border-blue-600 mb-2 mt-2 shadow-md focus:outline-none ",
        div_container_error: "my-2",
        text_error: "text-red-600",
      },
      div_content_button: "mt-10 w-full sm:text-center",
      button_restore_password:
        "px-5 py-2 w-full bg-red-800 hover:bg-red-900 text-white",
    };

    let rulevalidations = {
      educationLevel: {
        required: helpers.withMessage(
          "El campo es requerido",
          requiredIf(true)
        ),
      },
    };

    return {
      viewClassView,
      rulevalidations,
      schoolingRef
    };
  }
}
</script>