<template>
    <slot></slot>
    <VideoContract v-if="paymentFrecuency != '' && paymentMethod != '' && bussinesName != '' && textDescription != '' && textVideo != ''"
    :text_video="textVideo"
    :view_class_view="viewClassView"
    :payment_frecuency="paymentFrecuency"
    :payment_method="paymentMethod"
    :bussines_name="bussinesName"
    v-on:video-contract="changeVideoContract($event)"
    :text_description="textDescription" />
</template>
  
<script>
import { ref, toRef, computed } from 'vue';
export default {
    name: 'App',
    props:{
      data: {
        type: Object,
        default(){
          return {
            payment_frecuency: "",
            payment_method: "",
            bussines_name: "",
            text_video: "",
            text_description: ""
          };
        }
      }
    },
    setup(props, { emit }){
      let componentData = toRef(props, 'data');

      let paymentFrecuency = computed( ()=> (componentData.value != null)? componentData.value.payment_frecuency : '');
      let paymentMethod = computed( ()=> (componentData.value != null)? componentData.value.payment_method : '');
      let bussinesName = computed( ()=> (componentData.value != null)? componentData.value.bussines_name : '');
      let textVideo = computed(()=> (componentData.value != null)? componentData.value.text_video: '');
      let textDescription = computed( ()=> (componentData.value != null)? componentData.value.text_description : '');

      let viewClassView={
          title:"font-normal text-4xl pt-20",
          div_content_sub_text:"pb-5 mt-5",
          div_content_button:"mt-10 w-full sm:text-center",
          button_restore_password:"px-5 py-2 w-full bg-red-800 hover:bg-red-900 text-white",
      };
      
      function changeVideoContract(value){
        emit("video-contract", value)
      }

      return { 
        viewClassView,
        changeVideoContract,
        paymentFrecuency,
        paymentMethod,
        bussinesName,
        textVideo,
        textDescription
      }
    }
  }

  </script>