<template>
  <div>
    <slot></slot>
    <HousingInfo
      ref="housingInfoRef"
      :validations="rulevalidations"
      :view_class_view="viewClassView"
    />
  </div>
</template>
  
<script>
import { ref } from 'vue';
import {
  helpers,
  requiredIf,
} from "@vuelidate/validators";

export default {
  name: "HousingDataComponent",
  setup() {
    let housingInfoRef = ref(null); 
    let rulevalidations = {
      type: {
        require: helpers.withMessage("El campo es requerido", requiredIf(true)),
      },
      startOfResidency: {
        require: helpers.withMessage("El campo es requerido", requiredIf(true)),
      },
    };

    let viewClassView = {
      classInputs: {
        valid: "border-blue-600",
        no_valid: "border-red-600",
        default:
          "w-full py-2 px-2 rounded-md border-2 border-sm focus:border-blue-600 mb-2 mt-2 shadow-md focus:outline-none ",
      },
      div_container_error: {
        container: "my-2",
        text_error: "text-red-600",
      },
      div_container:
        "mt-10 grid grid-cols-1 sm:grid-cols-2 w-full gap-10 mx-auto",
      label_type_housing: "label-input w-full block tracking-wide",
      label_start_residency: "label-input w-full block tracking-wide",
    };

    return {
      viewClassView,
      rulevalidations,
      housingInfoRef
    };
  },
};
</script>